.main {
	.wrapper {
		> .inner {
			border: 1px solid $rd01;
		}
	}
	.content {
		display: flex;
		flex-wrap: wrap;
		@media screen and (min-width: $bpSp+1) { 
			padding: 1rem;
		}
		.form_wrapper {
			> section {
				border-bottom: none !important;
				padding-bottom: 0 !important;
				margin-bottom: 0 !important;
				&:last-child {
					margin-bottom: 1rem !important;
				}
			}
		}
		> * {
			flex-basis: 100%;
			max-width: 100%; // for ie11 bug
			@media screen and (min-width: $bpSp+1) { 
				flex-basis: 50%;
				max-width: 50%; // for ie11 bug
			}
			&:first-child {
				flex-basis: 100%;
				max-width: 100%; // for ie11 bug
			}
			&:nth-child(3) {
				@media screen and (min-width: $bpSp+1) { 
					padding: 0 0 1rem 1rem;
				}
			}
		}
		.article,
		section {
			padding: 1rem;
			&.w80p {
				@media screen and (min-width: $bpSp+1) { 
					flex-basis: 80%;
					max-width: 80%; // for ie11 bug
					margin-left: auto;
					margin-right: auto;
				}
			}
			header {
				@media screen and (min-width: $bpSp+1) { 
					display: flex;
					align-items: center;
				}
				margin-bottom: 1rem;
				h1 {
					margin-bottom: 0;
				}
				.note02 {
					@media screen and (min-width: $bpSp+1) { 						
						flex: 1;
						margin: 0 0 0 2rem;
					}
					@media screen and (max-width: $bpSp) { 
						margin-top: .5rem;
					}
					font-size: $fontS;
					line-height: 1.5;
				}
				.btn02 {
					padding: .75rem 2rem;
				}
			}
			h1 {
				color: $rd02;
				font-weight: normal;
				margin-bottom: 1rem;
				letter-spacing: .125rem;
				@media screen and (min-width: $bpSp+1) { 
					font-size: $fontXl;
					margin-bottom: 2rem;
				}
				
			}
			p {
				margin-bottom: 1.5rem;
				&:last-child {
					margin-bottom: 0;
				}
			}
			strong {
				margin-right: 1rem;
			}
			.paragraphs {
				p {
					line-height: 1.75rem;
				}
			}
			.btn01 {
				display: block;
				letter-spacing: .125rem;
				margin: 1rem auto 0;
				@media screen and (min-width: $bpSp+1) { 
					margin: 2rem auto 0;
					width: 17rem;
				}
			}
			&:nth-of-type(1) {
				border-bottom: 1px solid $rd01;
				padding-bottom: 2rem;
				margin-bottom: 1rem;
				.table01 {
					margin: 0 auto;					
					@media screen and (min-width: $bpSp+1) { 
						width: 80%;
					}
				}
				&:last-of-type {
					border: none;
					padding: 1rem 1rem 0;
				}
			}
			&:nth-of-type(2) {
				@media screen and (min-width: $bpSp+1) { 
					border-right: 1px solid $rd01;
				}
				&:last-child {
					border: none;
					padding: 1rem;
					flex-basis: 100%;
					max-width: 100%; // for ie11 bug
					@media screen and (max-width: $bpSp) { 
						padding-top: 0;
					}
				}
			}
			&:nth-of-type(3) {
				border-bottom: 1px solid $rd01;
			}
		}
		.article {
			display: flex;
			flex-wrap: wrap;
			> * {
				flex-basis: 100%;
				max-width: 100%; // for ie11 bug
			}
			> h1 {
				order: 2;
				+ .date {
					order: 1;
					color: $gy04;
					font-size: $fontS;
					margin-bottom: 1rem;
				}
			}
			.paragraphs {
				order: 3;
			}
		}
		> div {
			> section {
				&:nth-of-type(2) {
					border-right: none;
					p {
						margin-bottom: 0;
					}
				}
			}
		}
		.sidecol {
			padding: 1rem 1rem 1rem 1rem;
			> h1 {
				color: $rd02;
				font-size: $fontM;
				margin-bottom: 2rem;
				font-weight: bold;
				@media screen and (max-width: $bpSp) { 
					margin-bottom: 1rem;
				}
			}
			.list01 {
				li {
					margin-bottom: 1.5rem;
					@media screen and (max-width: $bpSp) { 
						margin-bottom: .5rem;
					}
					a {
						white-space: normal;
						.date {
							display: block;
							@media screen and (max-width: $bpSp) { 
								font-size: $fontS;
							}
						}
					}
				}
			}
		}
		&.cols2 {
			.article {
				@media screen and (min-width: $bpSp+1) { 
					flex-basis: 75%;
					max-width: 75%; // for ie11 bug
				}
			}
			.sidecol {
				@media screen and (min-width: $bpSp+1) { 
					flex-basis: 25%;
					max-width: 25%; // for ie11 bug
				}
				@media screen and (max-width: $bpSp) { 
					background: $rd01;
				}
				.toall {
					text-align: right;
					font-size: $fontS;
					@media screen and (max-width: $bpSp) { 
						margin-top: 1rem;
					}
				}
			}
		}
	}
}

// 202307追加 TASKO

.main {
	.content {
		.article,
		section {
			&:nth-of-type(1) {
				.table03 {
					width: 100%;
					td {
						width: max-content;
						> {
							width: max-content;
						}
					}
					thead {
						td {
							&:not(:first-of-type) {
								background: $rd01;
							}
							&:last-child {
								border-left: 1px solid $wt01;
							}
						}
					}
					tbody {
						td {
							padding: .25rem .5rem;
							border-bottom: none;
						}
						tr {
							&:nth-child(even) {
								td {
									&:not(:first-of-type) {
										background: $gy05;
									}
								}
							}
						}
					}
				}
				@media screen and (max-width: $bpSp) { 
					.wrapper_gradient {
						position: relative;
						&::after {
							content: "";
							position: absolute;
							right: 0;
							top: 0;
							height: 100%;
							width: 2rem;
							background: linear-gradient(90deg, #fff0, #ffff);
						}
					}
					.wrapper_scroll {
						overflow-x: scroll;
						.table03 {
							width: max-content;
							margin-right: 2rem !important;
							thead {
								display: table-header-group;
							}
						}
					}
				}
			}
		}
	}
}
