footer{
	background: $rd02;
	color: $wt01;
	font-size: $fontS;
	.wrapper {
		@media screen and (max-width: $bpSp) { 
			padding: 0 0 1rem;
		}
		> nav {
			display: flex;
			justify-content: space-between;
			margin: 0 auto 2rem;
			@media screen and (min-width: $bpSp+1) { 
				width: 80%;
			}
			@media screen and (max-width: $bpSp) { 
				flex-wrap: wrap;
				margin: 0 auto 1rem;
			}
			a {
				color: $wt01;
				text-align: center;
				width: 100%;
				border-left: 1px solid $rd03;
				padding: .5rem 0;
				transition: $tr05s;
				&:first-child {
					border-left: none;
				}
				&:hover {
					background: lighten($rd03, 5);
				}
				@media screen and (max-width: $bpSp) { 
					display: block;
					flex-basis: 100%;
					padding: 1rem 1.5rem;
					border-left: none;
					border-bottom: 1px solid $rd03;
				}
			}			
		}
		.copyright {
			text-align: center;
			font-size: $fontXs;
			letter-spacing: .125rem;
			@media screen and (max-width: $bpSp) { 
				letter-spacing: .05rem;
				padding: 1rem 1rem 0;
			}
		}
	}
}