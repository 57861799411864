.hnav {
	border-bottom: $rd01 1px solid;
	@media screen and (max-width: $bpSp) { 
		position: fixed;
		z-index: 5;
		width: 100%;
		top: 0;
		left: 0;
		background: #fff;
	}
	.wrapper {
		padding: 0;
		display: flex;
		align-items: center;
	}
	h1 {
		flex-basis: 25%;	
		text-align: left;
		padding: 0 2rem;
		@media screen and (max-width: $bpSp) { 
			flex-basis: 45%;
			padding: 1rem 1rem;
			max-width: 130px;
		}
		+ nav {
			flex-basis: 75%;
			display: flex;
			@media screen and (max-width: $bpSp) { 
				font-size: $fontS;
			}
			> * {
				display: flex;
				align-items: center;
			}
			.primal {
				flex-basis: 40%;
				@media screen and (max-width: $bpSp) { 
					flex-basis: 80%;
				}
				> * {
					width: 50%;
				}
				a {
					font-weight: bold;
					text-decoration: none;
					border: none;
					color: $bk01;
					padding: 1.5rem;
					border-left: 1px solid $rd01;
					display: block;
					text-align: center;
					@media screen and (max-width: $bpSp) { 
						padding: 1.5rem .5rem;
					}
					span {
						border-bottom: 3px solid transparent;
						transition: $tr05s;
					}
					&:hover {
						span {
							border-bottom-color: $rd02;
							padding-bottom: .25rem;
						}
					}
					&.current {
						background: $rd01;
						span {
							border-bottom-color: $rd02;
							padding-bottom: .25rem;
						}
					}
				}
			}
			.secondary {
				flex-basis: 60%;
				padding: 0 2rem;
				border-left: 1px solid $rd01;
				justify-content: space-around;
				@media screen and (max-width: $bpSp) { 
					display: none;
				    position: absolute;
				    z-index: 10;
				    background: #fff;
				    width: 100%;
				    flex-basis: 100%;
				    left: 0;
				    top: $spHeaderH + 2;
				    border-left: none;
				    padding: 0;
				    height: 100vh;
				}
				a {
					font-size: $fontS;
					@media screen and (max-width: $bpSp) { 
						padding: 1rem;
						border-bottom: 1px solid $rd01;
						display: block;
					}
				}
			}
			.sp_nav_toggle {
				@media screen and (min-width: $bpSp+1) { 
					display: none;
				}
				@media screen and (max-width: $bpSp) { 
					border-left: 1px solid $rd01;
					cursor: pointer;
					flex-basis: 25%;
					display: flex;
					img {
						width: 24px;
						display: block;
						margin: 0 auto;
					}
				}
			}
		}
	}
}

.hmessage {
	background: $gn04;
	color: $wt01;
	padding: .75rem 1rem;
	position: relative;
	font-size: $fontM;
	@media screen and (max-width: $bpSp) { 
		font-size: $fontS;
		line-height: 1.5;
	}
	> p {
		&:first-child {
			text-align: center;
			margin: 0 2rem;
		}
	}
	.btn_close {
		position: absolute;
		top: 50%;
		right: 1rem;
		margin-top: -1rem;
		padding: .45rem;
		border-radius: 1rem;
		transition: $tr05s;
		cursor: pointer;
		vertical-align: middle;
		i {
			width: 1rem;
			height: 1rem;
			line-height: 1;
		}
		&:hover {
			background-color: darken($gn02, 5);
		}
	}
}

.breadcrumb {
	font-size: $fontS;
	&.wrapper {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	li {
		display: inline;
		color: $rd02;
		a {
			color: $rd02;
			transition: $tr05s;
			&:after {
				content: ">";
				margin: 0 0 0 .5rem;
				display: inline-block;
				color: $rd02;
			}
			&:hover {
				color: $bk01;
				&:after {
					color: $rd02;
				}
			}
		}
	}
	+ * {
		padding-top: 0;
	}
}