// 検証用。納品時削除
.chart_container {
  width: 100%;
  height: 19rem;
  .chartjs-render-monitor {
    max-width: 100%;
  }
}

body {
  font-family: $sansSerif;
  color: $bk01;
  font-size: $fontM; // $unit = 16px = 1rem;
  line-height: 1;
  @media screen and (max-width: $bpSp) {
    padding-top: $spHeaderH;
  }
}

a {
  color: $bk01;
  text-decoration: none;
}

strong {
  font-weight: bold;
}

input,
textarea {
  border: $gy02 1px solid;
  font-size: $fontM;
  padding: 0.75rem 1rem;
  transition: $tr05s;
  @media screen and (max-width: $bpSp) {
    // 		font-size: $fontS;
  }
  &:hover {
    border-color: lighten($rd02, 25);
  }
  &:focus {
    outline: none;
    border-color: $rd02;
  }
}

.pcb {
  @media screen and (min-width: $bpSp+1) {
    display: block;
  }
  @media screen and (max-width: $bpSp) {
    display: none;
  }
}

.pci {
  @media screen and (min-width: $bpSp+1) {
    display: inline;
  }
  @media screen and (max-width: $bpSp) {
    display: none;
  }
}

.spb {
  @media screen and (min-width: $bpSp+1) {
    display: none;
  }
  @media screen and (max-width: $bpSp) {
    display: block;
  }
}

.spi {
  @media screen and (min-width: $bpSp+1) {
    display: none;
  }
  @media screen and (max-width: $bpSp) {
    display: inline;
  }
}

.ov_hidden {
  overflow: hidden;
}

.smaller {
  font-size: smaller;
}

.mt05 {
  margin-top: .5rem;
}

.mt1 {
  margin-top: 1rem;
}

// common classes
.wrapper {
  padding: 2rem;
  margin: 0 auto;
  width: calc(#{$innerW} + 4rem);
  box-sizing: border-box;
  @media screen and (max-width: $bpSp) {
    padding: 1rem;
    width: auto;
  }
}

.flex_half {
  display: flex;
  margin-bottom: 1rem;
  @media screen and (max-width: $bpSp) {
    flex-wrap: wrap;
  }
  > * {
    flex-basis: 50%;
    width: calc(50% - 1rem);
    &:first-child {
      margin-right: 1rem;
    }
    &:last-child {
      margin-left: 1rem;
    }
  }
}

.flex_single {
  display: flex;
  > * {
    flex-basis: 100% !important;
    border: none !important;
    border-bottom: 1px solid $rd01;
    &:nth-of-type(1) {
      padding-bottom: 0 !important;
    }
  }
}

.link01 {
  transition: $tr05s;
  border-bottom: 2px solid transparent;
  padding-bottom: 0.25rem;
  &:before {
    content: "▶︎";
    color: $nv01;
    transition: $tr05s;
  }
  &:hover {
    border-bottom-color: $nv01;
  }
}

.link02 {
  @extend .link01;
  &:before {
    content: "◀︎";
  }
}

.btn01 {
  background: $nv01;
  color: $wt01;
  font-size: $fontM;
  padding: 1rem;
  min-width: 17rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: $tr05s;
  display: inline-block;
  text-align: center;
  @media screen and (max-width: $bpSp) {
    width: 100%;
    min-width: auto;
  }
  &:hover {
    background: $nv02;
  }
}

.btn02 {
  background: $gy01;
  border: 1px solid $gy02;
  border-radius: 0.25rem;
  width: 100%;
  padding: 1.25rem;
  display: inline-block;
  text-align: center;
  transition: $tr05s;
  > i {
    margin: -1rem 0;
    display: inline-block;
  }
  &:hover {
    background: $gy02;
  }
}

.checkbox {
  position: absolute;
  display: none;
  + label {
    position: relative;
    display: block;
    cursor: pointer;
    vertical-align: middle;
    top: 1px;
    transition: $tr05s;
    padding: 0.25rem;
    color: $gy04;
    &:before {
      content: "";
      display: inline-block;
      width: 1rem;
      height: 1rem;
      background: $wt01;
      border: 1px solid $gy02;
      margin-right: 0.25rem;
      vertical-align: bottom;
      transition: $tr05s;
    }
    &:after {
      position: absolute;
      display: none;
      content: "";
      box-sizing: border-box;
      top: 0rem;
      left: 0.6rem;
      width: 0.5rem;
      height: 1rem;
      transform: rotate(45deg);
      border: solid 2px $bk01;
      border-top: 0;
      border-left: 0;
    }
    &:hover {
      background: $rd01;
      color: $bk01;
      &:before {
        border-color: $bk01;
      }
    }
  }

  &[disabled] {
    cursor: not-allowed;
    + label {
      cursor: not-allowed;
      color: $gy02;
      &:hover,
      &:before,
      &:after {
        cursor: not-allowed;
      }
      &:hover {
        &:before {
          transition: $tr05s;
          border: 1px solid $gy02;
        }
      }
      &:before {
        border: 1px solid $gy02;
      }
    }
  }
  &:checked {
    + label {
      color: $bk01;
      &:before {
        border: 1px solid $bk01;
        background: $wt01;
      }
      &:after {
        display: block;
      }
    }
    &[disabled] {
      + label {
        &:before {
          border: 1px solid $gy02;
          background: $gy02;
        }
      }
    }
  }
}

.checkbox_wrapper {
  flex-basis: 100%;
  margin: 0.25rem 0 0 12.5rem;
  @media screen and (max-width: $bpSp) {
    margin: 0.25rem 0 0 0;
  }
}

.table01 {
  border: 1px solid $rd01;
  @media screen and (max-width: $bpSp) {
    font-size: $fontS;
  }
  * {
    border-color: $rd01;
  }
  tr {
    td {
      padding: 0.75rem 1rem;
      vertical-align: middle;
      border-bottom: 1px solid $rd01;
      line-height: 1.5;
      .table_sub {
        display: block;
        padding: 0 0 0 1rem;
        text-align: left;
      }
      &:first-child {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        background: $rd01;
        border-color: $wt01;
        @media screen and (min-width: $bpSp+1) {
          text-align: center;
        }
      }
      @media screen and (max-width: $bpSp) {
        display: inline-block;
        width: 100%;
        padding: 0.5rem;
        &:first-child {
          padding: 0.25rem 0.5rem;
        }
      }
    }
    &:last-child {
      td {
        &:first-child {
          border-bottom: none;
        }
      }
    }
  }
}

.table02 {
  @extend .table01;
  font-size: $fontS;
  @media screen and (max-width: $bpSp) {
    flex-basis: 100%;
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  tr {
    td {
      padding: 0.25rem 0.5rem;
      width: 40%;
      &:first-child {
        border-top: 1px solid $rd01;
        width: 20%;
      }
      &:last-child {
        border-left: 1px solid $rd01;
      }
    }
  }
  thead {
    td {
      text-align: center;
      padding: 0.5rem;
      @media screen and (max-width: $bpSp) {
        display: table-cell;
      }
      &:first-child {
        background: $rd02;
        color: $wt01;
      }
      &:nth-child(2) {
        background: $rd01;
      }
      &:nth-child(3) {
        background: $gy01;
      }
    }
  }
  tbody {
    td {
      text-align: right;
      @media screen and (max-width: $bpSp) {
        display: table-cell;
      }
      &:first-child {
        text-align: center;
        background: $rd02;
        color: $wt01;
      }
    }
  }
  @media screen and (max-width: $bpSp) {
    border-top: none;
    &:last-child {
      thead {
        display: none;
      }
    }
  }
}

.table03 {
  @extend .table02;
  tbody {
    tr {
      td {
        text-align: center;
        /*
				&:first-child {
					background: $rd01;
					color: $bk01;
				}
        */
        &:last-child {
          padding: 0;
        }
        a {
          display: block;
          line-height: 2rem;
          transition: $tr05s;
          i {
            transition: $tr05s;
            position: relative;
            left: 0;
          }
          &:hover {
            color: $nv03;
            background: $gy01;
            i {
              left: -0.25rem;
            }
          }
        }
      }
    }
  }
}

.table04 {
  @extend .table01;
  tbody {
    tr {
      td {
        &:first-child {
          //	text-align: left;
        }
      }
    }
  }
}

.note01 {
  font-size: $fontXs;
}

.note02 {
  color: $gy03;
}

.note03 {
  font-size: $fontS;
  line-height: 1.75;
}

.list01 {
  font-size: $fontS;
  li {
    line-height: 1.5;
    margin-bottom: 0.5rem;
    .date {
      margin-right: 1.5rem;
      @media screen and (max-width: $bpSp) {
        margin-right: 0.5rem;
      }
    }
    a {
      @extend .link01;
      width: 100%;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: middle;
      border-bottom: none;
      padding-bottom: 0;
      @media screen and (max-width: $bpSp) {
        width: 80vw;
      }
      &:hover {
        color: $nv03;
      }
      &:before {
        content: "";
      }
    }
  }
}

.message {
  line-height: 2rem;
}

.help-block {
  display: block;
  font-size: $fontS;
  color: $rd03;
  border: 1px solid $rd03;
  padding: 0.5rem;
  margin-top: 0.5rem;
}

.main {
  .content {
    .form_wrapper {
      max-width: none;
    }
  }
}

.formset {
  width: 100%;
  padding-bottom: 2rem;
  ul {
    margin-bottom: 3rem;
    @media screen and (max-width: $bpSp) {
      margin-bottom: 2rem;
    }
  }
  li {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
    .flex {
      @media screen and (min-width: $bpSp + 1) {
        display: flex;
        align-items: center;
      }
    }
  }
  label {
    text-align: center;
    @media screen and (max-width: $bpSp) {
      text-align: left;
      display: block;
      margin-bottom: 0.5rem;
    }
    .note01 {
      @media screen and (min-width: $bpSp+1) {
        display: block;
        margin-top: 0.5rem;
      }
    }
    + input,
    + textarea,
    + .form-group {
      @media screen and (min-width: $bpSp+1) {
        margin-left: 0.75rem;
      }
    }
    + .form-group {
      flex: 2.67 !important;
    }
    + .form-group > * {
      &:first-child {
        width: 100%;
      }
    }
  }
  .note02 {
    margin-top: 1rem;
    font-size: $fontS;
  }
  .btn01 {
    text-align: center;
    margin: 0 auto;
    display: block;
  }
}

.formset02 {
  @extend .formset;
  margin: 0 auto;
  padding-bottom: 1rem;
  @media screen and (min-width: $bpSp+1) {
    width: 80%;
  }

  .flex {
    label {
      flex: 1;
      @media screen and (min-width: $bpSp+1) {
        text-align: right;
      }
      @media screen and (max-width: $bpSp) {
        font-size: $fontS;
      }
      + * {
        flex: 2.5;
        @media screen and (max-width: $bpSp) {
          width: 100%;
        }
      }
    }
    .link02 {
      top: 4.5rem;
      left: 1.5rem;
      display: inline-block;
      position: relative;
      @media screen and (max-width: $bpSp) {
        top: 0.5rem;
        left: 0;
      }
    }
    &.aligns {
      align-items: flex-start;
    }
  }
  ul {
    margin-bottom: 0;
  }
  .gap {
    flex: 1;
  }
  .checkbox {
    + label {
      display: inline-block;
    }
  }
  .capacity {
    &:after {
      content: "kW";
      margin-left: 0.25rem;
    }
  }
}

.cheader {
  border-bottom: 1px solid $rd01;
  @media screen and (min-width: $bpSp+1) {
    display: flex;
    align-items: center;
  }
  h1 {
    background: $rd02;
    color: $wt01;
    font-size: $fontL;
    text-align: center;
    padding: 0 2rem;
    height: $chH;
    line-height: $chH;
    flex: 1;
    @media screen and (max-width: $bpSp) {
      display: none;
    }
    + * {
      @media screen and (min-width: $bpSp+1) {
        display: flex;
        flex: 6;
        align-items: center;
      }
      padding: 1rem;
      > * {
        &:nth-of-type(1),
        &:nth-of-type(2) {
          flex: 2.5;
        }
      }
      > p {
        line-height: 1.5;
        @media screen and (max-width: $bpSp) {
          font-size: $fontS;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  .info {
    padding: 0;
    @media screen and (max-width: $bpSp) {
      display: flex;
      flex-wrap: wrap;
    }
    .news {
      padding: 0.5rem 1rem;
      flex: auto;
      @media screen and (max-width: $bpSp) {
        flex-basis: 100%;
        order: 2;
        width: 100%;
        // flex: none;
      }
      &.list01 {
        li {
          margin-bottom: 0;
          a {
            @media screen and (min-width: $bpSp+1) {
              width: 37rem;
            }
            @media screen and (max-width: $bpSp) {
              font-size: $fontS;
            }
          }
        }
      }
    }
    .btn_bubble {
      @media screen and (min-width: $bpSp+1) {
        flex: auto;
        width: 17%;
      }
      @media screen and (max-width: $bpSp) {
        order: 1;
        flex-basis: 100%;
        // flex: none;
      }
      a {
        background: $gn01;
        font-size: $fontS;
        display: block;
        vertical-align: baseline;
        transition: $tr05s;
        position: relative;
        @media screen and (max-width: $bpSp) {
          padding: 0.5rem;
        }
        @media screen and (min-width: $bpSp+1) {
          height: $chH;
          line-height: $chH;
          text-align: center;
          padding: 0 0.5rem;
          &:after {
            font-size: $fontM;
            content: "◀︎";
            position: absolute;
            left: -0.75rem;
            top: 0;
            color: $gn01;
            transition: $tr05s;
          }
        }
        &:hover {
          background: $gn02;
          &:after {
            color: $gn02;
          }
        }
      }
    }
  }
}

.toggler {
  border: 1px solid $rd02;
  border-radius: 0.25rem;
  display: flex;
  > * {
    flex-basis: 33.4%;
    padding: 0.5rem;
    text-align: center;
    background: $gy01;
    color: $rd02;
    border-left: 1px solid $rd02;
    &:first-child {
      border-radius: 0.25rem 0 0 0.25rem;
      border-left: none;
    }
    &:last-child {
      border-radius: 0 0.25rem 0.25rem 0;
    }
    &.active {
      background: $rd02;
      color: $wt01;
    }
  }
  a {
    transition: $tr05s;
    &:hover {
      background: $rd01;
    }
  }
}

.picker {
  display: flex;
  margin-bottom: 2rem;
  @media screen and (max-width: $bpSp) {
    flex-wrap: wrap;
  }
  > * {
    padding: 0.5rem;
    border-bottom: 1px solid $bk01;
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    transition: $tr05s;
    cursor: pointer;
    position: relative;
    @media screen and (max-width: $bpSp) {
      width: 100%;
      font-size: $fontS;
    }
    @media screen and (min-width: $bpSp+1) {
      flex: 1;
      &:first-child {
        margin-right: 1.5rem;
      }
      &:last-child {
        margin-left: 1.5rem;
      }
    }

    &:hover {
      background: $gn01;
    }
    &:after {
      content: "▼";
      color: $nv01;
      font-size: $fontM;
      margin-left: 2rem;
      position: absolute;
      right: 0.5rem;
    }
    .datepicker {
      position: relative;
      appearance: none;
      display: block;
      border: none;
      background: none;
      border-radius: 0;
      text-align: center;
      padding: 0.5rem 4rem 0.5rem 0.5rem;
      font-size: $fontL;
      cursor: pointer;
      font-size: $fontL;
      flex: 1;
      text-align: center;
      &:focus {
        outline: none;
      }
      @media screen and (max-width: $bpSp) {
        font-size: $fontM;
        padding: 0.25rem 0.5rem;
      }
    }
    > span {
      &:first-of-type {
        &:before {
          content: "●";
          color: $gn02;
          margin-right: 0.25rem;
        }
      }
    }
  }
  .date02 {
    .checkbox {
      + label {
        padding: 0;
        &:before {
          margin: -3px 1rem 0 0;
          display: block;
        }
        &:after {
          top: -0.5rem;
          left: 0.35rem;
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
    > span {
      &:first-of-type {
        //margin-right: 2rem;
        &:before {
          content: "◯";
          color: $gn02;
          margin-right: 0.25rem;
        }
      }
    }
  }
}

.date03 {
  select {
    position: relative;
    appearance: none;
    display: inline-block;
    border: none;
    background: none;
    border-radius: 0;
    border-bottom: 1px solid $bk01;
    text-align: center;
    padding: 0.5rem 2.5rem 0.5rem 1rem;
    font-size: $fontL;
    position: relative;
    cursor: pointer;
    transition: $tr05s;
    &::-ms-expand {
      display: none;
    }
    &:hover {
      background: $gn01;
    }
  }
  label {
    position: relative;
    &:after {
      display: block;
      content: "▼";
      color: $nv01;
      font-size: $fontM;
      position: absolute;
      left: 6rem;
      top: 0;
      pointer-events: none;
    }
  }
}

.date_form {
  flex: 1 !important;
}

.breadcrumb {
  + {
    .wrapper {
      min-height: calc(100vh - (65px + 46px + 121px));
    }
  }
}

.pagination_group {
  display: flex;
  align-items: center;
  margin: 2rem -1rem 0;
  .prev,
  .next {
    a {
      position: relative;
      transition: $tr05s;
      display: inline-block;
      padding: 0 0.5rem;
      transition: $tr05s;
    }
  }
  .prev {
    a {
      left: 0;
      &:hover {
        left: -0.5rem;
      }
    }
  }
  .next {
    a {
      right: 0;
      &:hover {
        right: -0.5rem;
      }
    }
  }
  .pagination {
    flex: 1;
    text-align: center;
    justify-content: space-between;
    > * {
      display: inline-block;
      box-sizing: border-box;
      margin: 0 0.5rem;
    }
    a {
      padding: 0.5rem;
      border: 1px solid transparent;
      transition: $tr05s;
      &:hover {
        border-color: $rd01;
      }
    }
    .active {
      font-weight: bold;
      color: $rd02;
    }
  }
}

.pagenav {
  display: flex;
  align-items: center;
  margin: 2rem 0 0;
  order: 99;
  > * {
    margin-bottom: 0 !important;
    flex-basis: 50%;
    a {
      display: flex;
      align-items: center;
      font-size: $fontS;
      line-height: 1.5;
      transition: $tr05s;
      > span {
        padding: 1rem;
        flex: 1;
      }
      .date {
        display: block;
      }
      i {
        transition: $tr05s;
        flex-basis: 24px;
        position: relative;
      }
      &:hover {
        color: $rd02;
      }
    }
    &.prev {
      margin-right: 0.5rem;
      a {
        i {
          left: 0;
        }
        &:hover {
          i {
            left: -0.25rem;
          }
        }
      }
    }
    &.next {
      a {
        i {
          right: 0;
        }
        padding-left: 0.5rem;
        border-left: 1px solid $rd01;
        &:hover {
          i {
            right: -0.25rem;
          }
        }
      }
    }
  }
}

.info_calls {
  background: $bl01;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: $bpSp) {
    padding: 1rem;
  }
  dl {
    line-height: 1.75;
    flex-basis: 50%;
    margin-bottom: 2rem;
    @media screen and (max-width: $bpSp) {
      flex-basis: 100%;
      margin-bottom: 1rem;
      line-height: 1.5;
    }
    &:last-child {
      flex-basis: 100%;
      margin-bottom: 0;
    }
    dt {
      font-weight: bold;
      &:before {
        content: "■";
        color: $rd02;
      }
      @media screen and (max-width: $bpSp) {
        margin-bottom: 0.5rem;
      }
    }
    dd {
      ul {
        li {
          @media screen and (min-width: $bpSp+1) {
            display: flex;
          }
          span {
            &:first-child {
              flex: 1;
            }
            &:last-child {
              flex: 2;
            }
          }
        }
      }
    }
  }
}

// 202307追加 TASKO

.summary01 {
  display: table;
  width: 100%;
  margin: -.75rem 0 1.5rem;
  > * {
    display: table-cell;
    width: max-content;
    padding: .5rem;
    // margin: 0 0 0 .5rem;
    border-left: 1px solid $rd01;
    text-align: center;
    vertical-align: middle;
    font-size: smaller;

    &:first-child {
      background-color: $rd01;
      // color: $wt01;
      margin-left: 0;
      // font-size: 1rem;
    }
    &:first-child,
    &:nth-child(2) {
      border-left: none;
    }
    @media screen and (max-width: $bpSp) {
      &:last-child {
        display: none;
      }
    }
  }
}
