.login {
	background: $gy01;
	@media screen and (max-width: $bpSp) { 
		padding-top: 0;
	}
	@media screen and (min-width: $bpSp+1) { 
		height: 100vh;
		padding: 2rem;
	}
	.wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
// 		height: calc(100% - 2rem);
		@media screen and (max-width: $bpSp) { 
// 			height: calc(96vh - 2rem);
			height: auto;
		}
		.inner {
			header {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				h1 {
					order: 2;
					margin-bottom: 3.5rem;
					width: 100%;
					text-align: center;
					@media screen and (max-width: $bpSp) { 
						width: 75%;
						margin-bottom: 2rem;
					}
				}
				.subtitle {
					order: 1;
					letter-spacing: .25rem;
					margin-bottom: 1rem;
					width: 100%;
					text-align: center;
					@media screen and (max-width: $bpSp) { 
						font-size: $fontS;
					}
				}
			}
			.message {
				text-align: center;
				@media screen and (min-width: $bpSp+1) { 
					margin: -1.5rem 0 2rem;
				}
				@media screen and (max-width: $bpSp) { 
					margin: 1rem 0;
					font-size: $fontM;
					line-height: 1.5;
				}
				margin-bottom: 2rem;
			}
			.formset {
				width: 30rem; // 480px;
				margin: 0 auto;
				@media screen and (max-width: $bpSp) { 
					width: 100%;
				}
				label {
					@media screen and (min-width: $bpSp+1) { 
						width: $labelW;
						text-align: right;
					}
					+ * {
						width: calc(100% - #{$labelW});
						@media screen and (max-width: $bpSp) { 
							width: 100%;
						}
					}
				}
				.note01 {
					@media screen and (max-width: $bpSp) { 
						margin-left: .25rem;
					}
				}
				.note02 {
					text-align: right;
					width: 100%;
				}
			}
		}
	}
	
	footer {
		text-align: center;
		height: 2rem;
		background: transparent;
		width: calc(#{$innerW} + 4rem);
		margin: 0 auto;
		@media screen and (max-width: $bpSp) { 
			width: auto;
			margin-bottom: 1rem;
		}
	}
}

