// units
$unit:		16px;
$innerW:	$unit * 60;
$labelW:	6rem;
$bpSp:		767px;
$spHeaderH:	61px;

// fonts
$sansSerif: -apple-system,BlinkMacSystemFont,"Helvetica Neue","Hiragino Kaku Gothic ProN",メイリオ,Meiryo,"游ゴシック Medium", YuGothic, YuGothicM, sans-serif;
$serif:		Georgia,"Hiragino Mincho ProN",游明朝,"Yu Mincho",YuMincho,HGS明朝E,serif;
$fontM:		$unit;			// 16px;
$fontL:		$fontM * 1.25;	// 20px
$fontXl:	$fontM * 1.6;	// 24px
$fontS:		$fontM * .875;	// 13px
$fontXs:	$fontM * .6875;	// 11px

// colors
$bk01:	#333333;	// テキストの墨色
$bk02:	#000000;	// 金クロ
$wt01:	#ffffff;	// シロ
$gy01:	#F8F8F8;	// 背景の極薄グレー
$gy02:	#E0E0E0;	// ボーダーの薄グレー
$gy03:	#666666;	// ボーダーの濃グレー
$gy04:	#B4B6B7;	// 日付等の文字のグレー
$gy05:	#f5f4f4;	// テーブルの交互行の背景用薄ピンクグレー
$nv01:	#0B5391;	// ボタン、リンク系の紺
$nv02:	darken($nv01, 10); // ボタンホバー時の暗い紺
$nv03:	lighten($nv01, 7.5); // 文字リンクのホバー時の明るい紺
$bl01:	#E5FCFF;	// 背景の水色
$or01:	#FBC400;	// ceneponロゴの明るいオレンジ
$or02:	#F39800;	// ceneponロゴの恋オレンジ
$gn01:	#E1F0F2;	// メッセージの薄い背景
$gn02:	#55B7B8;	// メッセージの濃い背景
$gn03:	#8FC31F;	// ヨーコちゃんの服の黄緑
$gn04:	#06888a;	// フラッシュメッセージの背景色
$rd01:	#F3DEDA;	// 罫線、背景などの薄い赤
$rd02:	#F45D40;	// キーとなる濃い赤
$rd03:	#E83517;	// ヨーコちゃんの赤


// transition
$tr05s:	all .5s ease;
$tr1s:	all 1s ease;
$tr2s:	all 2s ease;


// size
$chH: 90px;
